const NotFound404 = () => {
  return (
    <div className="404-page">
      <h1 className="page-title">404 Page Not Found</h1>
      <p>
        Head to the <a href="/">homepage</a>.
      </p>
    </div>
  );
};

export default NotFound404;
